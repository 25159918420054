#offers_section_mini
{
 width: 100%;
}

#offers_container_mini_int
{
 width: 1380px;
 display: flex;
 flex-direction: row;
 flex-shrink: 0;
 overflow-x: hidden;
 padding: 30px 0;
}

#offers_container_mini
{
 display: flex;
 flex-direction: row;
 justify-content: center;
}

.offer_main_mini
{
 width: 400px;
 border-radius: 15px;
 margin: 0 30px;
}

.offer_main_mini:hover
{
 box-shadow: var(--shadow-menu);
}

.offer_title_mini
{
 display: block;
 font-size: 1em;
 font-weight: bold;
 padding: 10px;
 margin: 5px 0;
 background-color: var(--button-color);
 border-radius: 15px 15px 0 0;
}

.offer_image_mini
{
 height: 100%;
 width: 100%;
}

.offer_img_mini
{
 width: 100%;
 height: 250px;
 border-radius: 0 0 15px 15px;
 vertical-align: bottom;
 object-fit: cover;
}

@media only screen and (max-width: 1440px) /* wide monitor */
{
 .offer_main_mini
 {
  width: 300px;
 }
 #offers_container_mini_int
 {
  width: 1080px;
  display: flex;
 }
}

@media only screen and (max-width: 768px) /* phone 768px */
{
.offer_main_mini
{
 width: 400px;
 margin: 0 15px;
}
#offers_container_mini
{
 display: flex;
 flex-direction: column;
 justify-content: center;
}
#offers_container_mini_int
{
 display: flex;
 width: 100%;
}
}

@media only screen and (max-width: 480px) /* phone 480px */
{
 .offer_main_mini
 {
  margin: 0 0;
 }
}