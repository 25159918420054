.offer_middle
{
 padding: 0px 100px;
}

.offer_main
{
 width: 100%;
 margin: auto;
 text-align: center;
 padding: 30px 0px;
}

.offer_img
{
 float: right;
 max-width: 50%;
 border-radius: 15px;
 margin: 0px 15px;
}

@media only screen and (max-width: 768px) /* phone 768px */
{
.offer_middle
{
 padding: 0;
}
.offer_img
{
 max-width: 100%;
 padding: 5px 5px;
}
}