.img_round_c
{
 border-radius:30px;
}

#gallery_container
{
 width: 100%;
 display: flex;
 flex-direction: column;
}

#gal_all_photos
{
 display: flex;
 flex-direction: row;
 align-content: flex-start;
 overflow-x: scroll;
 overflow-y: hidden;
 flex-wrap: nowrap;
 flex-shrink: 0;
 scrollbar-width: thin;
}

.photo_list_img_div
{
 width: 120px;
 height: 90px;
 text-align: center;
 margin-left: 20px;
 min-width:120px;
}

.photo_list_img_div:hover
{
 box-shadow: var(--shadow-menu-contact);
}

#gal_view_photo
{
 display: flex;
 flex-direction: row;
 max-height: 1000px;
 padding-bottom: 10px;
}

.div_over_img
{
 position: absolute;
 top: 0;
 width: 50%;
 height: 100%;
 cursor: pointer;
}

#div_left_ctrl
{
 left: 0;
}

#div_right_ctrl
{
 right: 0;
}

.gal_img_ctrl
{
 width: 50px;
 height: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}

#gal_img
{
 position: relative;
 width: calc(100% - 100px);
 height: 100%;
 text-align: center;
}
@media only screen and (max-width: 1280px) /* wide monitor */
{
	
}
@media only screen and (max-width: 768px) /* phone 768px */
{
  #gal_all_photos
 {
  display: none;
 }
 #gal_img
 {
  width: 100%;
  height: 300px;
 }
}
@media only screen and (max-width: 480px) /* phone 480px */
{
 #gal_all_photos
 {
  display: none;
 }
 .gal_img_ctrl
 {
  display: none;
 }
 #gal_img
 {
  width: 100%;
  height: 310px;
 }
}