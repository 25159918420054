.normal_window_base, .al_window_base
{
 z-index: 9;
 opacity: 0.5;
 background-color: var(--bg_color);
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 transition: opacity 0.6s;
}

.al_window_base
{
 z-index: 11;
}

.normal_window_container, .al_window_container
{
 z-index: 10;
 opacity: 1;
 transition: opacity 0.6s;
 background-color: var(--bg_color);
 border-radius: 10px;
 border-style: solid;
 border-width: 1px;
 border-color: #2e8ae1;
 background-image: url("https://www.nomadhomeoffice.eu/images/bg-2.jpg");
 background-size: cover;
 background-repeat: no-repeat;
 position: fixed;
 top: 100px;
 left: 50%;
 padding: 0 20px;
 margin-left: -375px;
 width: 750px;
 display: flex;
 flex-direction: column;
}

.al_window_container
{
 z-index: 12;
 height: 200px;
}

.normal_window_title, .al_window_title
{
 margin: 5px;
 line-height: 40px;
 border-style: solid;
 border-width: 0 0 1px 0;
 border-color: #2E8AE1;
 text-align: center;
}

.normal_window_msg, .al_window_msg
{
 margin: 5px;
 padding: 0 20px;
 text-align: center;
 overflow-Y: auto;
 scrollbar-color: #3F85EE black;
 scrollbar-width: thin;
}

.al_window_msg
{
 padding: 20px 20px;
 height: 80%;
}

.normal_window_ctrl, .al_window_ctrl
{
 padding: 5px;
 text-align: right;
 border-style: solid;
 border-color: #2E8AE1;
 border-width: 1px 0 0 0;
 margin: 5px;
}

.al_window_ctrl
{
 height: 20%;
}

.al_window_ctrl_btn
{
 min-width: 100px;
 color: white;
 background-color: var(--bg_color);
 border-style: solid;
 border-width: 2px;
 border-color: #2E8AE1;
 border-radius: 10px;
 margin: 0 5px;
 cursor: pointer;
 font-size: var(--button-font-size);
}